import { useRef, useEffect, useState } from 'react';
import p5 from 'p5';
import { Box, Button, Container, Grid, Typography } from '@mui/material';


interface CanvasProps {
    points: p5.Vector[][];
    canvasDimensions: { width: number, height: number };
}

export default function SignatureBoard() {
    const [points, setPoints] = useState<p5.Vector[][]>([]);
    const boxRef = useRef<HTMLDivElement>(null);
    const [canvasDimensions, setCanvasDimensions] = useState({
        width: 3 * window.innerWidth / 4,
        height: window.innerHeight / 2,
    });

    function handleClearPoints(){
        setPoints([]); // Clear the points
    }

    function handleDownload(){
        // Download the signature as an image
        const canvas = document.getElementsByTagName('canvas')[0] as HTMLCanvasElement;

        // Check if the canvas exists
        if (!canvas) {
            window.alert('Canvas not found! Please drop a message to the developer to fix this.');
            return;
        }

        // Create a data URL representing the image
        const url = canvas.toDataURL('image/jpeg');

        // Create a new link element
        const link = document.createElement('a');

        // Set the href of the link to the data URL
        link.href = url;

        // Set the download attribute of the link to the desired file name
        link.download = 'signature.jpg';

        // Append the link to the body
        document.body.appendChild(link);

        // Simulate a click on the link
        link.click();

        // Remove the link from the body
        document.body.removeChild(link);
    }

    useEffect(() => {
        const updateSize = () => {
            if (boxRef.current) {
                setCanvasDimensions({
                    width:  3 * window.innerWidth / 4,
                    height: window.innerHeight / 2,
                });
            }
        };

        window.addEventListener('resize', updateSize);
        updateSize(); // Update size on initial render

        // Cleanup
        return () => {
            window.removeEventListener('resize', updateSize);
        };
    }, []);

    return (
        // center the signature board using Container
        <Container maxWidth="xl" sx={{ textAlign: 'center' }}>
            <Typography variant="h4" gutterBottom>
                Signature Board
            </Typography>
            <Typography variant="body1" gutterBottom>
                Draw your signature here
            </Typography>
            <Box ref={boxRef} 
                sx={{
                    width: canvasDimensions.width,
                    height: canvasDimensions.height,
                    margin: 'auto',
                    border: 2, 
                    borderColor: 'black', 
                    borderRadius: 1 
                }}
            >
                <Canvas
                    points={points}
                    canvasDimensions={canvasDimensions}
                />
            </Box>
            <Box sx={{ height: 20 }} />
            {/* add a button to clear the points */}
            <Grid container spacing={3} direction="row" justifyContent="center">
                <Grid item>
                    <Button variant="contained" onClick={handleClearPoints}>
                    Clear
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" onClick={handleDownload}>
                        Download
                    </Button>
                </Grid>
            </Grid>

        </Container>
        // Add a button to clear the signature
    );
}

function Canvas(props: CanvasProps) {
    const canvasRef = useRef<HTMLDivElement>(null);

    const points = props.points;
    const canvasDimensions = props.canvasDimensions;

    useEffect(() => {
        if (canvasRef.current) {

            const canvas = new p5((p: p5) => {
                p.setup = () => {
                    p.createCanvas(canvasDimensions.width-5, canvasDimensions.height-5);
                    p.background(255);
                };

                p.draw = () => {
                    // Your p5.js drawing code here
                    p.stroke(0);
                    p.strokeWeight(2.5);
                    for (let i = 0; i < points.length; i++) {
                        for (let j = 0; j < points[i].length - 1; j++) {
                            p.line(points[i][j].x, points[i][j].y, points[i][j + 1].x, points[i][j + 1].y);
                        }
                    }
                };

                p.mousePressed = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                    points.push([]); // Start a new segment
                };

                p.mouseDragged = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                    if (p.mouseX < 0 || p.mouseX >= p.width || p.mouseY < 0 || p.mouseY >= p.height){
                        return;
                    }
                    const point = p.createVector(p.mouseX, p.mouseY);
                    points[points.length - 1].push(point);
                };

                p.touchStarted = (event: TouchEvent) => {
                    points.push([]); // Start a new segment
                }

                p.touchMoved = (event: TouchEvent) => {
                    if (p.mouseX < 0 || p.mouseX >= p.width || p.mouseY < 0 || p.mouseY >= p.height){
                        return;
                    }
                    const point = p.createVector(p.mouseX, p.mouseY);
                    points[points.length - 1].push(point);
                }

            }, canvasRef.current);
            
            return () => {
                canvas.remove();
            };
        }
    }, [canvasDimensions, points]);

    return (
            <div ref={canvasRef} />
        );
    };