import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, Typography } from "@mui/material";
import { Help as HelpIcon } from '@mui/icons-material';

import React, { useEffect, useRef } from 'react';
import p5 from 'p5';


const Fractal: React.FC = () => {
    const myRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        let zoom = 1;
        let xOffset = 0;
        let yOffset = 0;

        if (myRef.current === null) return;
        let canvas = new p5(sketch, myRef.current);

        function sketch(p: p5) {
            p.setup = () => {
                if (myRef.current === null) return;
                let rect = myRef.current.getBoundingClientRect();
                let currentY = rect.top;
                p.createCanvas(p.windowWidth - 10, p.windowHeight - currentY - 10);
                p.background(0);
            };

            p.draw = () => {
                p.background(0);
                p.stroke(255);
                p.translate(p.width / 2 + xOffset, p.height + yOffset);
                p.scale(zoom);
                branch(p, 275);
            };

            p.mouseDragged = () => {
                if (!mouseInside(p)) return;
                xOffset += p.mouseX - p.pmouseX;
                yOffset += p.mouseY - p.pmouseY;
            };

            p.windowResized = () => {
                if (myRef.current === null) return;
                let rect = myRef.current.getBoundingClientRect();
                let currentY = rect.top;
                p.resizeCanvas(p.windowWidth, p.windowHeight - currentY - 10);
            };

            p.mouseWheel = (event: WheelEvent) => {
                if (!mouseInside(p)) return;
                zoom -= event.deltaY * 0.001;
            };

            function mouseInside(p: p5) {
                return p.mouseX > 0 && p.mouseX < p.width && p.mouseY > 0 && p.mouseY < p.height;
            }

            function branch(p: p5, len: number) {
                p.stroke(255, 150, 0); // Change the color to orange
                p.strokeWeight(len / 10); // Change the stroke weight based on the length of the branch
                p.line(0, 0, 0, -len);
                if (len > 4) {
                    p.push();
                    p.translate(0, -len);
                    p.rotate(p.PI / 4);
                    branch(p, len * 0.67);
                    p.pop();
                    p.push();
                    p.translate(0, -len);
                    p.rotate(-p.PI / 4);
                    branch(p, len * 0.67);
                    p.pop();
                }
            }
        }
        
        return () => {
            canvas.remove();
        };
    }, []);

    return <div ref={myRef}></div>;
};


export default function FractalPage() {
    const [infoOpen, setInfoOpen] = React.useState(false);
    return (
        <Box>
            <Box display="flex" alignItems="center" justifyContent="center">
                
                <Box display="flex" alignItems="center" justifyContent="center">
                    <Typography variant="h4">
                        Fractals
                    </Typography>
                    <Tooltip title="Information about fractals">
                        <Button onClick={() => setInfoOpen(true)}>
                            <HelpIcon style={{ fontSize: 30 }}/>
                        </Button>
                    </Tooltip>
                </Box>

                <Dialog open={infoOpen} onClose={() => setInfoOpen(false)}>
                    <DialogTitle>Information about this canvas</DialogTitle>
                    <DialogContent>
                        1. You can zoom in and out using the mouse wheel.<br />
                        2. You can drag the fractal using the mouse.<br />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setInfoOpen(false)} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
            <Box sx={{ height: 20 }} />

            <Box marginLeft={0.5} marginRight={0.5}>
                <Fractal />
            </Box>
        </Box>
    );
}