import { Box, Typography } from '@mui/material';
function HomePage() {
  return (
    <Box textAlign="center">
      <Typography variant="h4" sx={{ textAlign: 'center' }}>
        Hello World...
      </Typography>
      <Typography variant="h6" sx={{ textAlign: 'center' }}>
        Debanjan Saha is live!
      </Typography>
      <Box sx={{ height: 20 }} />
    </Box>
  );
}

export default HomePage;
