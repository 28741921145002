import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link  as MUILink } from '@mui/material';
import { Link } from 'react-router-dom';
interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const drawerWidth = 240;
const resumeURL = "https://drive.google.com/file/d/12Va4sCN8uVMLlF0hlh-M3EiWeAhlkUuG/view?usp=drive_link"

export default function DrawerAppBar(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
      Debanjan Saha
      </Typography>
      <Divider />
      <List>
        <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
         <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'center' }}>
            <ListItemText primary="Home" />
          </ListItemButton>
          </ListItem>
        </Link>

        <Link to="/about" style={{ textDecoration: 'none', color: 'inherit' }}>
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary="About" />
            </ListItemButton>
          </ListItem>
        </Link>

        <Link to="/contact" style={{ textDecoration: 'none', color: 'inherit' }}>
          <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: 'center' }}>
                <ListItemText primary="Contact"/>
              </ListItemButton>
          </ListItem>
        </Link>

        <Link to="/experiments" style={{ textDecoration: 'none', color: 'inherit' }}>
          <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: 'center' }}>
                <ListItemText primary="Experiments"/>
              </ListItemButton>
          </ListItem>
        </Link>
        
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'center' }} href={resumeURL} target='_blank' rel='noopener'>
            <ListItemText primary="Resume"/>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'block', sm: 'block' } }}
          >
            Debanjan Saha
          </Typography>
          

          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            
            <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
              <Button sx={{ color: '#fff' }}>
                Home
              </Button>
              </Link>

            <Link to="/about" style={{ textDecoration: 'none', color: 'inherit' }}>
              <Button sx={{ color: '#fff' }}>
                About
              </Button>
            </Link>
            
            <Link to="/contact" style={{ textDecoration: 'none', color: 'inherit' }}>
              <Button sx={{ color: '#fff' }}>
                Contact
              </Button>
            </Link>

            <Link to="/experiments" style={{ textDecoration: 'none', color: 'inherit' }}>
              <Button sx={{ color: '#fff' }}>
                Experiments
              </Button>
            </Link>

            <MUILink 
              href={resumeURL}
              target="_blank" 
              rel="noopener"
            >
              <Button sx={{ color: '#fff' }}>
                Resume
              </Button>
            </MUILink>
              
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
     
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}