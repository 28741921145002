import { Container, Typography, Link } from "@mui/material"

export default function ContactPage() {
  return (
    <Container>
      <Typography variant="h4" sx={{ textAlign: 'center' }}>
        Contact me at
        <Link href="mailto:debanjansaha2001@gmail.com">
            <Typography variant="h6" sx={{ textAlign: 'center' }}>
                debanjansaha2001@gmail.com
            </Typography>
        </Link>
      </Typography>
    </Container>
  );
}