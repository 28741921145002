import { Box, Container, Typography } from "@mui/material"

export default function ErrorPage() {
  return (
    <Container>
      <Box sx={{ height: 100 }} />
      <Typography variant="h3" sx={{ textAlign: 'left' }}>
        404 Not Found
      </Typography>
    </Container>
  );
}