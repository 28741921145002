import { Box, Typography } from "@mui/material"

export default function AboutPage() {
  return (
    <Box textAlign="center">
      <Typography variant="h4" sx={{ textAlign: 'center' }}>
        About Me
      </Typography>
      <Box sx={{ height: 20 }} />
      <img src="/ai-dev.jpg" alt="AI Dev" style={{ width: '65%', height: 'auto' }} />

    </Box>
  );
}