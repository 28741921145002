import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

import {
  createBrowserRouter,
  RouterProvider,
  Outlet
} from 'react-router-dom';

import { Box } from '@mui/material';

import ErrorPage from './pages/ErrorPage';
import AboutPage from './pages/About';
import HomePage from './pages/Home';
import DrawerAppBar from './components/AppBar';
import ContactPage from './pages/Contact';
import ExperimentsPage from './pages/experiments/ExperimentsHome';
// import Placeholder from './pages/Placeholder';
import SignatureBoard from './pages/experiments/SignatureBoard';
import FractalPage from './pages/experiments/Fractal';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

function Layout() {
  return (
      <>
        <DrawerAppBar />
        <Box sx={{ height: 100 }} />
        <Outlet />
      </>
  );
}

const router = createBrowserRouter([
  {
    element: <Layout/>,
    errorElement: <ErrorPage />,
    children: [  
      {
        path: '/',
        element: <HomePage />
      },
      {
        path: '/about',
        element: <AboutPage />
      },
      {
        path: '/contact',
        element: <ContactPage />
      },
      {
        path: '/experiments',
        element: <ExperimentsPage />,
      },
      {
        path: '/experiments/signature-canvas',
        element: <SignatureBoard />
      },
      {
        path: '/experiments/fractals',
        element: <FractalPage />
      }
    ]
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
