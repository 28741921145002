import { Box, Card, CardContent, Container, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const items = [
    { 
        id: 1, 
        title: 'Connect-4-AI', 
        description: 'A Game i tried to implement while learning a few frontend technologies like reactjs, socketio etc', 
        href: 'https://seal-app-aihn7.ondigitalocean.app/', 
        external: true,
    },
    { 
        id: 2, 
        title: 'Signature Board', 
        description: 'Experimenting with p5.js. Tried to create a canvas where you can draw your signature with mouse and download the signature as an image.', 
        href: 'signature-canvas',
        external: false,
    },
    { 
        id: 3, 
        title: 'Fractals', 
        description: 'Fascinated by fractals. Trying to replicate using p5.js', 
        href: 'fractals',
        external: false,
    },
    // Add more items as needed
];
  

export default function ExperimentsPage() {
    
    return (
        <Container maxWidth="xl">
        <Grid container spacing={6}>
            {items.map(item => (
                <Grid key={item.id} item xs={12} sm={6} md={4} lg={4} >
                    <Link 
                        to={
                            item.external ? 
                            item.href : 
                            `/experiments/${item.href}`
                        }
                        style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                    {/* <Link to={`/experiments/${item.href}`} style={{ textDecoration: 'none', color: 'inherit' }}> */}
                        <Card style={{ height: '150px', border: '2px solid #000' }}>
                            <CardContent>
                            <Typography variant="h5" component="div">
                                {item.id}. {item.title}
                            </Typography>
                            <Box sx={{ height: 10 }} />
                            <Typography variant="body1" color="text.secondary">
                                {item.description}
                            </Typography>
                            </CardContent>
                        </Card>
                    </Link>
                </Grid>
            ))}
        </Grid>
        </Container>
    )
}